
.loader {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    flex-shrink: 0;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transform: scale(-0.4, 0.4);
}

@-webkit-keyframes ball-spin-fade-loader {
    50% {
        opacity: 0.3;
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes ball-spin-fade-loader {
    50% {
        opacity: 0.3;
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.ballSpinFadeLoader {
    position: relative;
    top: -10px;
    left: -10px;
}

.ballSpinFadeLoader > div:nth-child(1) {
    top: 25px;
    left: 0;
    -webkit-animation: ball-spin-fade-loader 1s -0.96s infinite linear;
    animation: ball-spin-fade-loader 1s -0.96s infinite linear;
}

.ballSpinFadeLoader > div:nth-child(2) {
    top: 17.04545px;
    left: 17.04545px;
    -webkit-animation: ball-spin-fade-loader 1s -0.84s infinite linear;
    animation: ball-spin-fade-loader 1s -0.84s infinite linear;
}

.ballSpinFadeLoader > div:nth-child(3) {
    top: 0;
    left: 25px;
    -webkit-animation: ball-spin-fade-loader 1s -0.72s infinite linear;
    animation: ball-spin-fade-loader 1s -0.72s infinite linear;
}

.ballSpinFadeLoader > div:nth-child(4) {
    top: -17.04545px;
    left: 17.04545px;
    -webkit-animation: ball-spin-fade-loader 1s -0.6s infinite linear;
    animation: ball-spin-fade-loader 1s -0.6s infinite linear;
}

.ballSpinFadeLoader > div:nth-child(5) {
    top: -25px;
    left: 0;
    -webkit-animation: ball-spin-fade-loader 1s -0.48s infinite linear;
    animation: ball-spin-fade-loader 1s -0.48s infinite linear;
}

.ballSpinFadeLoader > div:nth-child(6) {
    top: -17.04545px;
    left: -17.04545px;
    -webkit-animation: ball-spin-fade-loader 1s -0.36s infinite linear;
    animation: ball-spin-fade-loader 1s -0.36s infinite linear;
}

.ballSpinFadeLoader > div:nth-child(7) {
    top: 0;
    left: -25px;
    -webkit-animation: ball-spin-fade-loader 1s -0.24s infinite linear;
    animation: ball-spin-fade-loader 1s -0.24s infinite linear;
}

.ballSpinFadeLoader > div:nth-child(8) {
    top: 17.04545px;
    left: -17.04545px;
    -webkit-animation: ball-spin-fade-loader 1s -0.12s infinite linear;
    animation: ball-spin-fade-loader 1s -0.12s infinite linear;
}

.ballSpinFadeLoader > div {
    background-color: #fff;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
}
