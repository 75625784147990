.box {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  @media screen and (max-width: 767px){
    font-size: 20px;
  }
  &>div:first-child {
    margin-bottom: 30px;
  }
}
