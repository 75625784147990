@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700&display=swap");

@font-face {
  font-family: 'Sofia Pro';
  src: url('assets/fonts/SofiaPro/SofiaProRegular.woff') format('woff')
}

//BASIC STYLES
* {
  margin: 0;
  padding: 0;
  font: inherit;
  -webkit-tap-highlight-color: transparent;
}

*:not(input) {
  //-moz-user-select: none;
  //-webkit-user-select: none;
  //-ms-user-select: none;
  //user-select: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.postal-code-search .pac-item-query + span {
  display: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, *:before, *:after {
  box-sizing: border-box;
}

*:focus, *:active, *:hover {
  outline: none;
}

html, body {
  width: 100%;
  height: 100%;
}

html {
  cursor: default;
}

body {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #152144;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Disable user scaling globally */
  -webkit-user-scalable: no;
}

/* Or disable user scaling for specific elements */
input, select, textarea {
  -webkit-user-scalable: no;
}

.hiddenInput {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  z-index: 1000;
  left: -1000;
}

.LinesEllipsis {
  width: 100%;
  flex-shrink: 0;
}

/* Scroll Bar */
::-webkit-scrollbar {
  width: 8px;
  height: 0;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #CFD7E6;
  width: 8px;
  border-radius: 4px;
}


@supports (-moz-appearance: none) {
  /* Styles here will only be applied in Firefox */
  * {
    scrollbar-width: thin; /* Firefox implementation */
    scrollbar-color: #CFD7E6 transparent; /* Firefox implementation */
  }
}



/* Google autocomplete */
.pac-item {
  height: 40px;
  font-size: 14px;

  &:hover, &-selected {
    background-color: #CFF1FF;
    cursor: pointer;
  }

  &-query {

    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #152144;
    line-height: 40px;
  }
}

.pac-icon-marker {
  margin-top: 10px;
}


/* Google map */
.gm-style-mtc, .gm-svpc {
  display: none;
}

//Time picker
.MuiPickersToolbar-toolbar {
  background-color: #4bc09b !important;
}

.MuiPickersToolbarText-toolbarTxt {
  color: #ffffff !important;
  font-weight: 400 !important;
  font-family: "Open Sans", sans-serif;
  opacity: 0.8;
}

.MuiPickersToolbarText-toolbarBtnSelected {
  font-weight: 700 !important;
  opacity: 1;
}

.MuiPickersClock-pin, .MuiPickersClockPointer-pointer, {
  background-color: #4bc09b !important;
}

.MuiPickersClockPointer-thumb {
  border-color: #4bc09b !important;
  background-color: #4bc09b !important;
}

.MuiPickersClockPointer-noPoint {
  background-color: #4bc09b !important;
}

.MuiPickersClockNumber-clockNumber {
  font-family: "Open Sans", sans-serif;
  color: #ffffff !important;
  font-weight: 700 !important;
}

.MuiPickersClock-clock {
  background-color: #949494 !important;
}

.blinking-text {
  animation: blink-and-scale 1.5s 2;
}

@keyframes blink-and-scale {
  0%, 100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  25% {
    opacity: 0.5;
    transform: translateY(-5px) scale(1.05); /* Move text up */
  }
  50% {
    opacity: 1;
    transform: translateY(5px) scale(1); /* Move text down */
  }
  75% {
    opacity: 0.5;
    transform: translateY(-5px) scale(1.05); /* Move text up */
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
