.op_toast {
  background-color: #152144 !important;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
  @media (max-width: 699px) {
    border-radius: 4px;
    top: 55px !important;
  }
}

.op_toast_close {
  font-size: 18px;
  color: #98A5B7;
}
